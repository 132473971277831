import {Auth} from "aws-amplify";


const login = async (email, password) => {
  let result = { user: null, error: null };
  try {
    let user = await Auth.signIn(email, password)
    // console.log(user);
    // let attributes = await Auth.userAttributes(user);
    result["user"] = user;
  } catch (e) {
    console.log(e);
    result["error"] = e;
  }

  return result;
}

const completePasswordChange = async (user, password) => {
  let result = { success: false, error: null, user: user };
  try {
    let result = Auth.completeNewPassword(user, password);
    // at this time the user is logged in if no MFA required

    result["success"] = true;
  } catch (e) {
    console.log(e);
    result["error"] = e;
  }
  return result;
};

const getCurrentUser = async () => {
  let user = null;
  try {
    user = await Auth.currentAuthenticatedUser({ bypassCache: true })
  } catch (e) {
    console.log(e);
  }
  return user;
};

const logout = async () => {
  try {
    await Auth.signOut();
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const forgotPassword = async (email) => {
  try {
    let result = await Auth.forgotPassword(email);
    console.log(result);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const handleConfirmClick = async (email, code, password) => {
  try {
    let result = await Auth.forgotPasswordSubmit(
        email,
        code,
        password
    );
    console.log(result);
    return { success: true };
  } catch (e) {
    console.log(e);
    return { success: false, error: e };
  }
}

const CSAuth = {
  login: login,
  completePasswordChange: completePasswordChange,
  getCurrentUser: getCurrentUser,
  logout: logout,
  forgotPassword: forgotPassword,
  handleConfirmClick: handleConfirmClick
};

export default CSAuth;