import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


export default function SearchSelection(props) {
  const [val, setVal] = React.useState(props.default ? props.default : '');


  const handleChange = (event, newValue) => {
    setVal(event.target.value);

    if (props.callback) {
      props.callback(newValue);
    }
  };

  return (
      <Autocomplete
          options={props.values}
          onChange={(event, newValue) => handleChange(event, newValue)}
          getOptionLabel={(option) => option.value}
          style={{ width: 400, maxWidth: "100%" }}
          renderInput={(params) =>
              <TextField {...params} label={props.label} variant="outlined"/>
          }
      />
  )
};