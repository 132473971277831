import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ReportingFunctions from "../../../reporting";
import ReportTable from "../ReportTable";
import Typography from "@material-ui/core/Typography";
import {CardContent} from "@material-ui/core";
import Card from "@material-ui/core/Card";


const styles = {};

const aggColumns = [
  { key: "month", label: "Month" },
  { key: "domain", label: "Platform" },
  { key: "mins_watched", label: "Mins watched", numeric: false },
  { key: "net_loyalty", label: "Net royalties", numeric: false },
  { key: "nr_purchases", label: "Nr. purchases", numeric: false }
];

const prodRevColumns = [
  { key: "month", label: "Month" },
  { key: "product_category", label: "Product Category" },
  { key: "net_loyalty", label: "Net royalties", numeric: false },
];

const topLicensorsColumns = [
  { key: "licensor", label: "Licensor" },
  { key: "net_loyalty", label: "Net royalties", numeric: false },
  { key: "mins_watched", label: "Mins. watched", numeric: false },
  { key: "nr_purchases", label: "Nr. purchases", numeric: false },
];

const topTitlesColumns = [
  { key: "title", label: "Title" },
  { key: "net_loyalty", label: "Net royalties" },
  { key: "mins_watched", label: "Mins. watched" },
  { key: "nr_purchases", label: "Nr. purchases" },
];

const fundsColumns = [
  { key: "month", label: "Month" },
  { key: "domain", label: "Platform" },
  { key: "product_category", label: "Product category" },
  { key: "amount_received", label: "Amount received", format: true },
  { key: "taxes", label: "Taxes", format: true },
  { key: "fees", label: "Fees", format: true },
  { key: "nr_transactions", label: "Nr. transactions", format: true },
  { key: "amount_refunded", label: "Amount refunded", format: true },
  { key: "total_refunds", label: "Total refunds", format: true },
  { key: "reporting_revenue", label: "Reporting revenue", format: true },
];

const getColValueFormatted = (val_) => {
  if (!val_) return "-";
  if (isNaN(val_)) return val_;

  try {
    let formatted = val_.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (!(formatted.includes("."))) {
      formatted += ".00";
    }
    let parts = formatted.split(".");

    if (parts.length > 1) {
      formatted = parts[0] + "." + parts[1].replace(",", "").slice(0, 2);
    } else {
      formatted = parts[0] + ".00";
    }

    return formatted;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getFormattedTableData = (rows, reference) => {
  let data = [];

  for (let row of rows) {
    let datum = {};
    for (let aReference of reference) {
      if (aReference.format) {
        datum[aReference.key] = getColValueFormatted(row[aReference.key]);
      } else {
        datum[aReference.key] = row[aReference.key];
      }
    }
    data.push(datum);
  }
  return data;
};

const getTotalF = (rows, col) => {
  let rowsF = [];
  for (let v of rows) {
    let x = {};
    x[col] = Number(v[col].replace(",", ""));
    rowsF.push(x);
  }

  let val_ = rowsF.map((row) => row[col]).reduce((sum, i) => sum + i, 0);
  return getColValueFormatted(val_);
}

const getTotal = (rows, col) => {
  let val_ = rows.map((row) => row[col]).reduce((sum, i) => sum + i, 0);
  return getColValueFormatted(val_);
}

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: props.company,
      ...props.props
    }
    // console.log(props);
  }

  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.

    let mnth = props.month;
    let interval = props.interval;
    let quarter = props.quarter;

    if ((mnth !== state.month) || (interval !== state.interval)
        || (quarter !== state.quarter)) {
      return {
        month: props.month,
        interval: props.interval,
        quarter: props.quarter,
      }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.state.month !== prevState.month) ||
        (this.state.quarter !== prevState.quarter) ||
        (this.state.interval !== prevState.interval)
    ) {
      console.log("load data");
      this.handleStats();
    }
  }

  componentDidMount() {
    this.handleStats();
  }

  handleStats() {
    if (this.state.interval && this.state[this.state.interval]) {
      let that = this;
      ReportingFunctions.getStats(this.state.interval, this.state[this.state.interval], this.state.company)
      .then(data => {
        that.setState(
            {
              ...this.state,
              ["reportData"]: data,
            },
            () => {
              console.log(this.state);
            }
        );
      });
    }
  }


  render() {
    return (
        <div className={"block"}>
          <Grid container spacing={2}>
            {this.state.reportData ?
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3} sm={6}>
                    <Card style={{ backgroundColor: "#2b2c2c", color: "white" }}>
                      <CardContent>
                        <Typography variant="h5" color={"inherit"}>
                          Funds received
                        </Typography>
                        <Typography variant="h6" color={"inherit"}>
                          {getTotal(this.state.reportData.funds, "amount_received")}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={3} sm={6}>
                    <Card style={{ backgroundColor: "#2b2c2c", color: "white" }}>
                      <CardContent>
                        <Typography variant="h5" color={"inherit"}>
                          Reporting revenue
                        </Typography>
                        <Typography variant="h6" color={"inherit"}>
                          {getTotal(this.state.reportData.funds, "reporting_revenue")}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={3} sm={6}>
                    <Card style={{ backgroundColor: "#2b2c2c", color: "white" }}>
                      <CardContent>
                        <Typography variant="h5" color={"inherit"}>
                          Net royalties
                        </Typography>
                        <Typography variant="h6" color={"inherit"}>
                          {getTotalF(this.state.reportData.agg, "net_loyalty")}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={3} sm={6}>
                    <Card style={{ backgroundColor: "#2b2c2c", color: "white" }}>
                      <CardContent>
                        <Typography variant="h5" color={"inherit"}>
                          Transactions
                        </Typography>
                        <Typography variant="h6" color={"inherit"}>
                          {getTotal(this.state.reportData.funds, "nr_transactions")}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                : null
            }

            {this.state.reportData ?
                <Grid item xs={12} lg={12} sm={12}>
                  <Typography variant="h4" style={{ fontWeight: "bold" }} color={"primary"}>
                    Funds overview
                  </Typography>
                  <ReportTable data={{ items: getFormattedTableData(this.state.reportData.funds, fundsColumns), columns: fundsColumns }}/>
                </Grid>
                : null
            }
            {this.state.reportData ?
                <Grid item xs={12} lg={12} sm={12}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }} color={"primary"}>
                    Revenue, purchases and minutes watched by month
                  </Typography>
                  <ReportTable data={{ items: this.state.reportData.agg, columns: aggColumns }}/>
                </Grid>
                : null
            }
            {this.state.reportData ?
                <Grid item xs={12} lg={12} sm={12}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }} color={"primary"}>
                    Revenue by product category
                  </Typography>
                  <ReportTable data={{ items: this.state.reportData.rev_product, columns: prodRevColumns }}/>
                </Grid>
                : null
            }
            {this.state.reportData ?
                <Grid item xs={12} lg={12} sm={12}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }} color={"primary"}>
                    Top Licensors
                  </Typography>
                  <ReportTable data={{ items: this.state.reportData.top_licensors, columns: topLicensorsColumns }}
                               per_page={10}/>
                </Grid>
                : null
            }
            {this.state.reportData ?
                <Grid item xs={12} lg={12} sm={12}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }} color={"primary"}>
                    Top Titles
                  </Typography>
                  <ReportTable data={{ items: this.state.reportData.top_titles, columns: topTitlesColumns }}
                               per_page={10}/>
                </Grid>
                : null
            }

          </Grid>

        </div>
    );
  }
}

export default withStyles(styles)(Analytics);