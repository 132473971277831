import * as React from 'react';
import {forwardRef} from 'react';
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Delete from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import {Dialog, DialogActions, DialogTitle, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};


const editUser = (user) => {
  console.log(user);
};


export default function AccountsTable(props) {
  const columns = [];
  const rows = [];
  const [open, setOpen] = React.useState(false);
  const [updateState, setUpdateState] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState(false);
  const [userToUpdate, setUserToUpdate] = React.useState(false);

  const deleteUserIntent = (user) => {
    setUserToDelete(user);
    setOpen(true);
    console.log(user);
  };

  const closeDeleteUserUi = () => {
    setOpen(false);
  };

  const deleteUser = () => {
    setOpen(false);
    setUserToDelete(null);
  };

  const closeUpdateUserUi = () => {
    setUpdateState(false);
  };

  const updateUser = () => {
    setUpdateState(false);
    setUserToUpdate(null);
  };


  const updateUserIntent = (user) => {
    setUserToUpdate(user);
    setUpdateState(true);
    console.log(user);
  };

  for (let val of props.columns) {
    let column = { field: val.key, title: val.label };
    columns.push(column);
  }

  columns.push({ field: "delete", title: "Actions" });

  for (let item of props.items) {
    item["delete"] =
        <div><Edit onCli/><Delete onClick={() => deleteUserIntent(item)}/></div>
    rows.push(item);
  }


  return (
      <div style={{ maxWidth: '100%', marginTop: "10px", border: '1px solid grey' }}>
        <div>
          {userToDelete ?
              <Dialog open={open}>
                <DialogTitle
                    id="alert-delete">{`Do you want to delete the user ${userToDelete.name} (${userToDelete.company})?`}</DialogTitle>
                <DialogActions>
                  <Button onClick={closeDeleteUserUi} color="primary">
                    No
                  </Button>
                  <Button onClick={deleteUser} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              : ''}
        </div>
        <div>
          {userToUpdate ?
              <Dialog open={updateState}>
                <DialogTitle
                    id="alert-update">{"Update user"}
                </DialogTitle>
                <div style={{padding: 20}}>
                  <Typography variant="p" color={"primary"}>
                    Note: Do not change company of Admins.
                  </Typography>
                  <br></br>
                  <br></br>
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} lg={12} sm={12}>
                      <TextField id="input-name" fullWidth={true} label="Name" defaultValue={`${userToUpdate.name}`}/>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} lg={12} sm={12}>
                      <TextField id="input-company" fullWidth={true} label="Company" defaultValue={`${userToUpdate.company}`}/>
                    </Grid>
                  </Grid>

                </div>
                <DialogActions>
                  <Button onClick={closeUpdateUserUi} color="primary">
                    No
                  </Button>
                  <Button onClick={updateUser} color="primary" autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              : ''}
        </div>
        <MaterialTable
            columns={columns}
            data={rows}
            title={""}
            icons={tableIcons}
            options={{
              pageSize: 20,
              emptyRowsWhenPaging: false,
              exportButton: true,
              search: true,
              sorting: true,
              headerStyle: { fontWeight: "bold", padding: "8px" },
              rowStyle: { fontSize: "0.875rem", padding: "8px" },
              cellStyle: { padding: "8px" },
            }}
        />
      </div>
  );
}