import * as React from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import reporting from "../../reporting";

const useStyles = makeStyles(theme => ({
  root: {
    margin: '5px',
  },
}));

export default function PdfButton(props) {
  const classes = useStyles();
  const licensor = props.licensor;
  const allowDownloads = props.allowDoanloads;
  const [loading, setLoading] = React.useState(false);

  const clicked = async () => {
    setLoading(true);
    let result = await reporting.getPDFReport(licensor.key, props.interval, props.period, props.company);

    if (result.success) {
      let url = result["pdf_result"]["url"];

      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${result["pdf_result"]["filename"]}`);
        document.body.appendChild(link);
        link.click();
      }
    }
    setLoading(false);
  };

  let label = "Download pdf";

  return (
      <Button variant="contained"
              className={classes.root}
              disabled={!allowDownloads ? true : loading}
              onClick={clicked}
              color="primary">
        {label}
      </Button>

  );
};