import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import ReportingFunctions from "../../../reporting";
import ReportTableM from "../ReportTableM";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PdfButton from "../PdfButton";
import ExcelButton from "../ExcelButton";

const styles = {};


class LicensorReporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    }
    this.populate();
  }

  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.

    let mnth = props.month;
    let interval = props.interval;
    let quarter = props.quarter;

    if ((mnth !== state.month) || (interval !== state.interval)
        || (quarter !== state.quarter)) {
      return {
        month: props.month,
        interval: props.interval,
        quarter: props.quarter,
      }
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.populate();

    let toUpdate = false;

    if (this.state.licensor && !prevState.licensor) {
      toUpdate = true;
    }

    if (this.state.licensor && prevState.licensor) {
      if ((this.state.month !== prevState.month) ||
          (this.state.quarter !== prevState.quarter) ||
          (this.state.interval !== prevState.interval)
      ) {
        toUpdate = true;
      }
    }

    if (toUpdate) {
      this.handleLicensor();
    }
  }


  componentDidMount() {
    this.handleLicensor();
  }

  populate() {
    if (!this.state.licensor) {
      this.updateLicensor();
    }
    // this.getReport();
  }


  updateLicensor() {
    let that = this;
    ReportingFunctions.getLicensor(this.state.company)
    .then(data => {
      if (data) {
        that.setState(
            {
              ...this.state,
              ["licensor"]: {key: data.licensor_id, licensor: data.licensor},
            },
            () => {
              // console.log(this.state);
            }
        );
      }
    });
  }

  handleLicensor(licensor) {
    if (!licensor) {
      licensor = this.state.licensor;
    }

    if (this.state.interval && licensor) {
      let that = this;
      ReportingFunctions.getReport(licensor.key, this.state.interval, this.state[this.state.interval])
      .then(data => {
        let overviwData = {
          nr_purchases: data.overview.item.nr_purchases ? data.overview.item.nr_purchases : 0,
          net_loyalty: data.overview.item.net_loyalty ? data.overview.item.net_loyalty : 0,
          mins_watched: data.overview.item.mins_watched ? data.overview.item.mins_watched : 0
        };

        that.setState(
            {
              ...this.state,
              ["detailsData"]: data.details,
              ["aggData"]: data.agg,
              ["overviewData"]: overviwData,
              ["allowDownloads"]: data.allowDownloads,
              ["licensor"]: licensor,
            },
            () => {
              // console.log(this.state);
            }
        );
      });
    }
  }


  render() {
    let info = [
      `Month - ${this.state.month ? this.state.month : ""}`,
      `Quarter - ${this.state.quarter ? this.state.quarter : ""}`,
      `Interval - ${this.state.interval ? this.state.interval : ""}`,
      `Licensor - ${this.state.licensor ? this.state.licensor.value : ""}`
    ];

    return (
        <div>
          {this.state.overviewData ?
              <div>
                <div style={{ padding: "16px" }}>
                  <Grid item xs={12} lg={12} sm={12}>
                    <PdfButton licensor={this.state.licensor}
                               period={this.state[this.state.interval]}
                               interval={this.state.interval}
                               company={null}
                               allowDoanloads={this.state.allowDownloads}
                    />
                    <ExcelButton licensor={this.state.licensor}
                                 period={this.state[this.state.interval]}
                                 interval={this.state.interval}
                                 company={this.state.company}
                                 allowDoanloads={this.state.allowDownloads}
                    />
                  </Grid>
                </div>

                {!this.state.allowDownloads ?
                    <div style={{ padding: "5px", marginLeft: "16px", backgroundColor: "rgb(255, 244, 229)" , display: "inline-block"}}>
                      <div>
                        <p>Amazon reporting is not complete.</p>
                      </div>
                    </div> : null
                }

                <div id="report" style={{ padding: "16px" }}>
                  <Typography variant="h6">
                    Overview
                  </Typography>
                  <Grid item xs={12} lg={12} sm={12}>
                    <Typography paragraph={true}>
                      Net loyalties: {this.state.overviewData.net_loyalty}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12} sm={12}>
                    <Typography paragraph={true}>
                      Total viewing time {this.state.overviewData.mins_watched} mins
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12} sm={12}>
                    <Typography paragraph={true}>
                      Total nr. purchases {this.state.overviewData.nr_purchases}
                    </Typography>
                  </Grid>
                  <ReportTableM data={this.state.aggData} title={"Aggregated by platform"}/>
                  <ReportTableM data={this.state.detailsData} title={"Titles"}/>
                </div>
              </div>
              : null
          }
        </div>

    );
  }
}

export default withStyles(styles)(LicensorReporting);