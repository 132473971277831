import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./auth/Auth";
import ResetPassword from "./pages/auth/ResetPassword";
import NotFound from "./pages/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import UnauthenticatedRoute from "./pages/auth/UnauthenticatedRoute";
import FAQ from "./pages/faq/FAQ";


export default ({ childProps }) =>
    <Switch>
      <AppliedRoute path="/" exact component={Home} props={childProps}/>
      <AppliedRoute path="/login" exact component={Login} props={childProps}/>
      <AppliedRoute path="/reset" exact component={ResetPassword} props={childProps}/>
      <AppliedRoute path="/faq" exact component={FAQ} props={childProps}/>
      <UnauthenticatedRoute
          path="/reset"
          exact
          component={ResetPassword}
          props={childProps}
      />
      { /* Finally, catch all unmatched routes */}
      <Route component={NotFound}/>
    </Switch>;