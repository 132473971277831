import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
  block: {
    margin: 10
  },
});

function DebugInfo(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, info } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };


  return (
      <Dialog className={classes.block} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Info</DialogTitle>
        {info.map((k, index) => {
          return <Typography key={index} className={classes.block} variant="body1">{k}</Typography>
        })}

      </Dialog>
  );
}

DebugInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function DebugInfoR(props) {
  const [open, setOpen] = React.useState(false);
  const info = props.info;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
      <div className={"block"}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Debug info
        </Button>
        <DebugInfo  info={info} open={open} onClose={handleClose} />
      </div>
  );
}
