import React, {Component} from "react";
import {Button, Grid, Paper, TextField, withStyles} from '@material-ui/core';
import {Email, Fingerprint} from '@material-ui/icons'
import PropTypes from "prop-types";
import "./Login.css";
import {observer} from "mobx-react";
import appStore from "./../../store/AppStore";
import CSAuth from "./../../auth/Auth";
import { Link } from "react-router-dom";

const styles = theme => ({
  margin: {
    margin: theme.spacing(5),
    // textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: "1px solid"
  }
});


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      error: null,
      requiresPasswordChange: false,
      userAttributes: {}
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
      error: null
    });
  }

  handlePasswordChange = async () => {

    let pwdChangeResult = await CSAuth.completePasswordChange(this.state.user, this.state.password);

    if (pwdChangeResult.error) {
      this.setState({
        error: "Your password did not match the criteria",
      }, () => {
        // console.log(this.state);
      })
    } else {
      this.setState({
        requiresPasswordChange: false,
      }, () => {
        // console.log(this.state);
      })
      await this.handleLogin();
    }
  }

  handleLogin = async () => {
    if ((this.state.email.length === 0) || (this.state.password.length === 0)) {
      this.setState({
        error: "Email or Password cannot be empty",
      }, () => {
        console.log(this.state);
      })
    } else {
      let loginResult = await CSAuth.login(this.state.email, this.state.password);

      let user = loginResult.user;
      let requiresPasswordChange = false;

      if ((user) && (user.challengeName === "NEW_PASSWORD_REQUIRED")) {
        let requiredAttributes = user.challengeParam.requiredAttributes;
        let userAttributes = user.challengeParam.userAttributes;
        requiresPasswordChange = true;

        let stateUserAtrributes = {}
        for (let i = 0; i < requiredAttributes.length; i++) {
          let key = requiredAttributes[i];
          stateUserAtrributes[key] = userAttributes[key];
        }

        user["attributes"] = stateUserAtrributes;

        this.setState({
          requiresPasswordChange: true,
          user: user,
          password: "",
        }, () => {
          // console.log(this.state);
        })
      }

      let loginError = loginResult.error;
      if (loginError) {
        if (loginError.code === "NotAuthorizedException") {
          this.setState({
            error: "Incorrect email or password",
          }, () => {
            // console.log(this.state);
          })
        }
      }

      if (user && !loginError && !requiresPasswordChange) {
        this.setState({
          user: user
        }, () => {
          appStore.setUser(user);
          window.location.reload(false);
        })
      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    await this.handleLogin();
  }

  render() {
    const { classes } = this.props;

    let passwordChangeHtml = "sss";
    let loginHtml = "";

    if (this.state.requiresPasswordChange) {
      passwordChangeHtml = <Grid container spacing={6} justify="center">
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <h4>Please change your password</h4>
            <div className="p-rules">
              <p>Min. length of the password is 8.
                Require numbers.
                Require uppercase and letters.
              </p>
            </div>
            <p>{this.state.email}</p>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Fingerprint/>
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField id="password"
                           label="Password" type="password" fullWidth required
                           value={this.state.password}
                           onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: '10px' }}>
              <Button variant="outlined"
                      color="primary"
                      style={{ textTransform: "none" }}
                      onClick={this.handlePasswordChange}
              >Submit
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    } else {
      loginHtml = <Grid container spacing={6} justify="center">
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <div className={classes.margin}>
              <Grid container spacing={8} alignItems="flex-end" justify="center"
                    alignItems="center">
                <Grid item>
                  <Email/>
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField id="email" label="Email" type="email"
                             fullWidth autoFocus required
                             value={this.state.email}
                             onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <Fingerprint/>
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField id="password"
                             label="Password" type="password" fullWidth required
                             value={this.state.password}
                             onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <div className="fgt-pwd">
                    <Link to="/reset">Forgot password?</Link>
                  </div>
                  {/*<Button disableFocusRipple disableRipple*/}
                  {/*        style={{ textTransform: "none" }} variant="text"*/}
                  {/*        color="primary">Forgot password ?*/}
                  {/*</Button>*/}
                </Grid>
              </Grid>
              <Grid container justify="center" style={{ marginTop: '10px' }}>
                <Button variant="contained"
                        color="primary"
                        style={{ textTransform: "none" }}
                        onClick={this.handleSubmit}
                >Login
                </Button>
              </Grid>
              <p className="error">{this.state.error}</p>
            </div>
          </Paper>
        </Grid>
      </Grid>
    }

    return (
        <div className="login">
          {this.state.requiresPasswordChange ? passwordChangeHtml : loginHtml}
        </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(observer(Login));