import * as React from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import reporting from "../../reporting";

const useStyles = makeStyles(theme => ({
  root: {
    margin: '5px',
  },
}));

export default function MailButton(props) {
  const classes = useStyles();
  const isTest = props.isTest;
  const [loading, setLoading] = React.useState(false);

  let label = "Send report";
  if (isTest) {
    label = "Send test report";
  }

  const clicked = async () => {
    setLoading(true);

    console.log(props);

    let params = {
      licensor_id: props.licensor.key,
      interval: props.interval,
      period: props.period,
      email: props.email,
      company: props.company
    };
    let result = await reporting.mailReport(params);
    // console.log(result);

    setLoading(false);
  }

  return (
      <Button variant="contained"
              className={classes.root}
              onClick={clicked}
              disabled={loading}
              color="primary">
        {label}
      </Button>
  );
};