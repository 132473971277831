import React, {Component} from "react";
import Container from "@material-ui/core/Container";
import {withStyles} from "@material-ui/core/styles";
import AdminDashboard from "./admin/AdminDashboard";
import LicensorDashboard from "./licensor/LicensorDashboard";


const styles = theme => ({
  root: {
    margin: "0.35em"
  }
});

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.

    let user = props.user;

    if (!state.user && user) {
      return { user: user };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  render() {
    const user = this.state.user;
    let userType = null;
    if (user) {
      userType = user.attributes["custom:type"];
    }

    return (
        <div>
          <Container maxWidth="xl">
            {!userType ? "" :
                userType === "admin" ?
                    <AdminDashboard
                        company={user.attributes["custom:company"]}
                        email={user.attributes["email"]}
                    /> :
                    <LicensorDashboard
                        company={user.attributes["custom:company"]}
                        email={user.attributes["email"]}
                    />
            }

          </Container>

        </div>
    )
  }
}

export default withStyles(styles)(Dashboard);