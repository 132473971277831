import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Selection from "../../Selection";
import ReportingFunctions from "../../../reporting";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Reporting from "./Reporting";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Analytics from "./Analytics";
import Accounts from "./Accounts";

const styles = {};

const intervals = [
  { key: "month", value: "Month" },
  { key: "quarter", value: "Quarter" },
];

class AdminDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interval: 'month',
      month: null,
      quarter: null,
      months: null,
      quarters: null,
      currentTab: 0,
      company: props.company,
      email: props.email
    };
  }


  componentDidMount() {
    // this.updateLicensors()
    this.updatePeriods()
  }

  handleTabChange(val, newVal) {
    this.setState(
        {
          ...this.state,
          ["currentTab"]: newVal
        },
        () => {
          // console.log(this.state);
        }
    );
  }

  updateLicensors() {
    let that = this;
    ReportingFunctions.getLicensors(null)
    .then(data => {
      let licensors = [];
      for (let x of data) {
        licensors.push({ key: x.licensor_id, value: x.licensor })
      }
      that.setState(
          {
            ...this.state,
            ["licensors"]: licensors,
          },
          () => {
            // console.log(this.state);
          }
      );
    });
  }

  updatePeriods() {
    let that = this;
    ReportingFunctions.getPeriods()
    .then(data => {

      let months = [];
      for (let i of data.months) {
        months.push({ key: i, value: i });
      }

      let quarters = [];
      for (let i of data.quarters) {
        quarters.push({ key: i, value: i });
      }

      that.setState(
          {
            ...this.state,
            ["months"]: months,
            ["month"]: months[months.length - 1].value,
            ["quarters"]: quarters,
            ["quarter"]: quarters[quarters.length - 1].value,
          },
          () => {
            // console.log(this.state);
          }
      );
    });
  }

  handleInterval(interval) {
    this.setState(
        {
          ...this.state,
          ["interval"]: interval
        },
        () => {
          // console.log(this.state);
        }
    );
  }

  handleMonth(month) {
    this.setState(
        {
          ...this.state,
          ["month"]: month
        },
        () => {
          // console.log(this.state);
        }
    );
  }

  handleQuarter(quarter) {
    this.setState(
        {
          ...this.state,
          ["quarter"]: quarter
        },
        () => {
          // console.log(this.state);
        }
    );
  }

  render() {
    let useMonths = false;
    let useQuarters = false;

    if (this.state.interval) {
      if ((this.state.interval === "month") && this.state.months) {
        useMonths = true;
      }
      if ((this.state.interval === "quarter") && this.state.quarters) {
        useQuarters = true;
      }
    }

    return (
        <div>
          <div className={"block"}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2}>
                <Selection label={"Interval"}
                           values={intervals}
                           default={this.state.interval}
                           callback={(e) => this.handleInterval(e)}
                />
              </Grid>
              {useMonths ?
                  <Grid item xs={12} lg={2}>

                    <Selection label={"Month"}
                               values={this.state.months}
                               default={this.state.month ? this.state.month : this.state.months[this.state.months.length - 1].value}
                               callback={(e) => this.handleMonth(e)}
                    />
                  </Grid>
                  : null
              }
              {useQuarters ?
                  <Grid item xs={12} lg={2}>

                    <Selection label={"Quarter"}
                               values={this.state.quarters}
                               default={this.state.quarter ? this.state.quarter : this.state.quarters[this.state.quarters.length - 1].value}
                               callback={(e) => this.handleQuarter(e)}
                    />
                  </Grid>
                  : ""
              }
            </Grid>
          </div>

          <Tabs centered value={this.state.currentTab}
                onChange={(event, newValue) => this.handleTabChange(event, newValue)}
                aria-label="simple tabs example">
            <Tab label="Reporting"
            />
            <Tab label="Analytics"/>
            <Tab label="Accounts"/>
          </Tabs>

          <Grid item xs={12} lg={12}>
            {this.state.currentTab === 0 &&
            <Reporting month={this.state.month}
                       interval={this.state.interval}
                       quarter={this.state.quarter}
                       company={this.state.company}
                       email={this.state.email}
                       company={this.state.company}
            />
            }
            {this.state.currentTab === 1 &&
            <Analytics month={this.state.month}
                       interval={this.state.interval}
                       quarter={this.state.quarter}
                       company={this.state.company}
            />
            }
            {this.state.currentTab === 2 &&
            <Accounts/>
            }
          </Grid>
          <Divider/>
        </div>

    );
  }
}

export default withStyles(styles)(AdminDashboard);