import React, {Component} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import './App.css';
import appStore from "./store/AppStore";
import CAppBar from "./AppBar";
import {Provider} from "mobx-react";

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      isAuthenticated: false,
      user: null,
      isAuthenticating: true,
      page: "home"
    };

  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      user: this.state.user,
    };

    const stores = { appStore };
    return (
        <Provider {...stores}>
          <CAppBar/>
        </Provider>
    );
  }
}

export default App;
