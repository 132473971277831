import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Selection(props) {
  const classes = useStyles();
  const [val, setVal] = React.useState(props.default ? props.default: '');


  const handleChange = (event) => {
    setVal(event.target.value);

    if (props.callback) {
      props.callback(event.target.value);
    }
  };

  return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
          <Select
              labelId="demo-simple-select-label"
              value={val}
              onChange={handleChange}
          >
            {
              props.values.map((value, index) => (
                  <MenuItem key={value.key} value={value.key}>{value.value}</MenuItem>
              ))
            }

          </Select>
        </FormControl>
      </div>
  )
}