import {autorun, makeObservable, observable} from "mobx";

class AppStore {

  user = null;
  _cnt = 0

  persisted = {};

  constructor() {
    makeObservable(this, {
      user: observable,
      _cnt: observable
    });

    autorun(() => console.log(this.user));

    this.setFromStrorage();
  }

  load() {
    let jsonS = localStorage.getItem('store');
    if (jsonS) {
      let json = JSON.parse(jsonS);
      return json;
    }

    return {}
  }

  setFromStrorage() {
    let persisted = this.load();

    if (persisted.count) {
      this._cnt = persisted.count;
    }

    if (persisted.user) {
      this.user = persisted.user;
    }

  }

  getUser() {
    return this.user;
  }

  setUser(user) {
    this.user = user;
    this.persisted["user"] = this.user;
    localStorage.setItem('store', JSON.stringify(this.persisted));
  }

  getCnt() {
    return this._cnt;
  }

  setCnt(value) {
    this._cnt = this._cnt + value;

    this.persisted["count"] = this._cnt;

    localStorage.setItem('store', JSON.stringify(this.persisted));
  }
}

const appStore = new AppStore();

export default appStore;