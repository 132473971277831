import React, {Component} from "react";
import {observer} from "mobx-react";
import appStore from "./../../store/AppStore";
import Login from "../../components/login/Login";
import Dashboard from "../../components/dashboard/Dashboard";
import CSAuth from "./../../auth/Auth";

class Welcome extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.check().then(r => console.log());
  }

  check = async () => {
    let user = await CSAuth.getCurrentUser();
    if (!user && appStore.getUser()) {
      appStore.setUser(user);
    }
    if (user && !appStore.getUser()) {
      appStore.setUser(user);
    }

    this.setState({
      user: user,
    }, () => {
      // console.log(this.state);
    })
  }

  render() {
    return (
        <div>
          {appStore.user ? <Dashboard user={this.state.user}/> : <Login/>}
        </div>
    )
  }
}

export default observer(Welcome);