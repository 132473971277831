import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ReportingFunctions from "../../../reporting";
import Typography from "@material-ui/core/Typography";
import AccountsTable from "../AccountsTable";


const styles = {};

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.props
    }
    // console.log(props);
  }

  componentDidMount() {
    this.handleStats();
  }

  handleStats() {
    let that = this;
    ReportingFunctions.getAccounts()
    .then(data => {
      console.log(data);
      that.setState(
          {
            ...this.state,
            ["accounts"]: data,
          },
          () => {
            // console.log(this.state);
          }
      );
    });
  }


  render() {
    return (
        <div className={"block"}>
          {this.state.accounts ?
              <Grid item xs={12} lg={12} sm={12}>
                <Typography variant="h5" style={{ fontWeight: "bold" }} color={"primary"}>
                  Accounts
                </Typography>
                (Work in progress ... )
                <AccountsTable items={this.state.accounts.items}
                               columns={this.state.accounts.columns}
                               title={"Accounts"}
                               per_page={10}/>
              </Grid>
              : null
          }
        </div>
    );
  }
}

export default withStyles(styles)(Accounts);