import * as React from 'react';
import { forwardRef } from 'react';
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


export default function ReportTableM(props) {
  const columns = [];
  const data = props.data;
  const rows = data.items;
  const title = props.title;

  for (let val of data.columns) {
    let column = { field: val.key, title: val.label };

    if (val["numeric"]) {
      column["customSort"] = (a, b) => {

        let x = 0;
        let y = 0;

        if (a[val.key]) {
          x = Number(a[val.key].replace(",", ""));
        }

        if (b[val.key]) {
          y = Number(b[val.key].replace(",", ""));
        }

        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      };
    }

    columns.push(column);
  }


  return (
      <div style={{ maxWidth: '100%', marginTop: "10px", border: '1px solid grey' }}>
        <MaterialTable
            columns={columns}
            data={rows}
            title={title}
            icons={tableIcons}
            options={{
              pageSize: rows.length,
              emptyRowsWhenPaging: false,
              exportButton: true,
              search: true,
              paging: false,
              sorting: true,
              headerStyle: {fontWeight:"bold", padding: "8px"},
              rowStyle: {fontSize:"0.875rem", padding: "8px"},
              cellStyle: {padding: "8px"},
            }}
        />
      </div>
  );
}