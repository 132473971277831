import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import {withStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import Routes from "./Routes";
import './App.css';
import AppBar from "@material-ui/core/AppBar";
import Menu from "@material-ui/core/Menu";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from "@material-ui/core/MenuItem";
import {observer} from "mobx-react";
import appStore from "./store/AppStore";
import CSAuth from "./auth/Auth";

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  name: {
    flexGrow: 1,
    position: "absolute",
    right: 0
  }
}));

class CAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      page: "home"
    };
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, page: "home" });
  };

  logout = async () => {
    try {
      await CSAuth.logout();
      appStore.setUser(null);
      this.setState({ anchorEl: null, page: "home" });
    } catch (e) {
      console.log("problem logging out");
    }
  }

  handlePage = (event, value) => {
    this.setState({ anchorEl: null, page: value });
    appStore.setCnt(1);
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const { classes } = this.props;

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      user: this.state.user,
    };

    let logout;

    if (appStore.getUser()) {
      logout = <MenuItem key={20} onClick={this.logout}>Logout</MenuItem>;
    } else {
      logout = "";
    }

    let name = "";
    let companyName = "";

    let user = appStore.getUser();

    if (user) {
      if (user.attributes) {
        name = user.attributes.name ? user.attributes.name : user.attributes.email;

        if (user["attributes"]["custom:type"] === "licensor") {
          companyName = " | " + user["attributes"]["custom:company"];
        }

      }
    }

    return (
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" className={classes.menuButton}
                          onClick={this.handleMenu}
                          onClose={this.handleClose}
                          color="inherit" aria-label="menu">
                <MenuIcon/>
              </IconButton>
              <Menu
                  open={open}
                  anchorEl={anchorEl}
              >
                <MenuItem
                    key={0}
                    selected={"home" === this.state.page}
                    onClick={event => this.handlePage(event, "home")}
                ><Link to="/">
                  Home
                </Link>
                </MenuItem>
                <MenuItem
                    key={2}
                    selected={"faq" === this.state.page}
                    onClick={event => this.handlePage(event, "faq")}
                >
                  <Link to="/faq">FAQ
                  </Link>
                </MenuItem>
                {logout}
              </Menu>
              <div>
                <Typography variant="h6" className={classes.title}>
                  Contentscope Reporting
                </Typography>
              </div>
              <div style={{ position: "absolute", right: 10 }}>
                {name} {companyName}
              </div>
            </Toolbar>
          </AppBar>
          <Routes childProps={childProps}/>
        </div>
    );
  }
}

CAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(observer(CAppBar));