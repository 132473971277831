const url = "https://b7x2k1fltc.execute-api.eu-central-1.amazonaws.com/prod/cs-reporting/";

const getLicensors = (company) => {
  let companyN = "contentscope";

  if (company) {
    companyN = company.replace(/\s/g, '').toLowerCase();
  }

  return fetch(url + "licensors?company=" + companyN)
  .then(response => response.json())
  .then(data => {
    return data
  });
};

const getLicensor = (company) => {
  if (!company) return {};

  let payload = {
    method: 'post',
    body: JSON.stringify({ company: company }),
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(url + "licensor", payload)
  .then(response => response.json())
  .then(data => {
    return data
  }).catch(e => console.log(e));

  return {};
};

const getPeriods = () => {
  return fetch(url + "intervals")
  .then(response => response.json())
  .then(data => {
    return data
  });
};

const getReport = (licensorId, interval, period) => {
  let url_ = `${url}report?licensor_id=${licensorId}&interval=${interval}&period=${period}`;
  return fetch(url_)
  .then(response => response.json())
  .then(data => {
    return data
  }).catch((e) => console.log(e));
};

const getAccounts = () => {
  let url_ = `${url}accounts`;
  return fetch(url_)
  .then(response => response.json())
  .then(data => {
    return data
  });
};

const getStats = (interval, period, company) => {
  let url_ = `${url}stats?interval=${interval}&period=${period}`;
  if (company) {
    url_ += `&company=${company}`;
  } else {
    url_ += "&company=UNKNOWN";
  }

  console.log(url_);

  return fetch(url_)
  .then(response => response.json())
  .then(data => {
    return data
  });
};

const getPDFReport = async (licensorId, interval, period, company) => {
  let apiPayload = {
    method: 'post',
    body: JSON.stringify({
      licensor_id: licensorId,
      interval: interval,
      period: period,
      company: company
    }),
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(url + "reportPdf", apiPayload)
  .then(response => response.json())
  .then(data => {
    return data;
  }).catch(e => {
    return { success: false, error: e };
  });
};

const mailReport = async (params) => {
  let queryStringParameters = {};

  Object.keys(params).forEach(key => {
    queryStringParameters[key] = params[key];
  })

  let apiPayload = {
    method: 'post',
    body: JSON.stringify(queryStringParameters),
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(url + "reportMail", apiPayload)
  .then(response => response.json())
  .then(data => {
    return data;
  }).catch(e => {
    return { success: false, error: e };
  });
};

const getExcelReport = async (licensorId, interval, period) => {
  let apiPayload = {
    method: 'post',
    body: JSON.stringify({
      licensor_id: licensorId,
      interval: interval,
      period: period,
    }),
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(url + "reportExcel", apiPayload)
  .then(response => response.json())
  .then(data => {
    return data;
  }).catch(e => {
    return { success: false, error: e };
  });
};

const ReportingFunctions = {
  getPeriods: getPeriods,
  getLicensors: getLicensors,
  getLicensor: getLicensor,
  getReport: getReport,
  getStats: getStats,
  getPDFReport: getPDFReport,
  getExcelReport: getExcelReport,
  mailReport: mailReport,
  getAccounts: getAccounts
};

export default ReportingFunctions