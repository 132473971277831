import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Selection from "../../Selection";
import ReportingFunctions from "../../../reporting";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LicensorReporting from "./LicensorReporting";

const styles = {};

const intervals = [
  { key: "month", value: "Month" },
  { key: "quarter", value: "Quarter" },
];

class LicensorDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interval: 'month',
      month: null,
      quarter: null,
      months: null,
      quarters: null,
      company: props.company
    };
  }


  componentDidMount() {
    // this.updateLicensors()
    this.updatePeriods()
  }


  updatePeriods() {
    let that = this;
    ReportingFunctions.getPeriods()
    .then(data => {

      let months = [];
      for (let i of data.months) {
        months.push({ key: i, value: i });
      }

      let quarters = [];
      for (let i of data.quarters) {
        quarters.push({ key: i, value: i });
      }

      that.setState(
          {
            ...this.state,
            ["months"]: months,
            ["month"]: months[months.length - 1].value,
            ["quarters"]: quarters,
            ["quarter"]: quarters[quarters.length - 1].value,
          },
          () => {
            // console.log(this.state);
          }
      );
    });
  }

  handleInterval(interval) {
    this.setState(
        {
          ...this.state,
          ["interval"]: interval
        },
        () => {
          // console.log(this.state);
        }
    );
  }

  handleMonth(month) {
    this.setState(
        {
          ...this.state,
          ["month"]: month
        },
        () => {
          // console.log(this.state);
        }
    );
  }

  handleQuarter(quarter) {
    this.setState(
        {
          ...this.state,
          ["quarter"]: quarter
        },
        () => {
          // console.log(this.state);
        }
    );
  }

  render() {
    let useMonths = false;
    let useQuarters = false;

    if (this.state.interval) {
      if ((this.state.interval === "month") && this.state.months) {
        useMonths = true;
      }
      if ((this.state.interval === "quarter") && this.state.quarters) {
        useQuarters = true;
      }
    }

    return (
        <div>
          <div className={"block"}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={2}>
                <Selection label={"Interval"}
                           values={intervals}
                           default={this.state.interval}
                           callback={(e) => this.handleInterval(e)}
                />
              </Grid>
              {useMonths ?
                  <Grid item xs={12} lg={2}>

                    <Selection label={"Month"}
                               values={this.state.months}
                               default={this.state.month ? this.state.month : this.state.months[this.state.months.length - 1].value}
                               callback={(e) => this.handleMonth(e)}
                    />
                  </Grid>
                  : null
              }
              {useQuarters ?
                  <Grid item xs={12} lg={2}>

                    <Selection label={"Quarter"}
                               values={this.state.quarters}
                               default={this.state.quarter ? this.state.quarter : this.state.quarters[this.state.quarters.length - 1].value}
                               callback={(e) => this.handleQuarter(e)}
                    />
                  </Grid>
                  : ""
              }
            </Grid>
          </div>

          <Grid item xs={12} lg={12}>
            <LicensorReporting month={this.state.month}
                               interval={this.state.interval}
                               quarter={this.state.quarter}
                               company={this.state.company}
            />
          </Grid>
          <Divider/>
        </div>

    );
  }
}

export default withStyles(styles)(LicensorDashboard);