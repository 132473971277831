import React, {Component} from "react";
import appStore from "./../../store/AppStore";
import {observer} from "mobx-react";
import "./ResetPassword.css";
import CSAuth from "./../../auth/Auth";
import Grid from "@material-ui/core/Grid";
import {Button, Paper, TextField, withStyles} from "@material-ui/core";
import {Email, Fingerprint, Security} from "@material-ui/icons";
import {Link} from "react-router-dom";

const styles = theme => ({
  margin: {
    margin: theme.spacing(5),
    // textAlign: 'center',
  },
  padding: {
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: "1px solid"
  }
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    console.log(appStore.getUser());

    this.state = {
      code: "",
      email: "",
      password: "",
      message: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
      error: null
    });
  }

  handleSendCodeClick = async event => {
    event.preventDefault();

    try {
      let result = await CSAuth.forgotPassword(this.state.email);
      this.setState({ codeSent: result, message: "Please check your email for password reset code." });
    } catch (e) {
      alert(e);
      this.setState({ isSendingCode: false });
    }
  };

  handleConfirmClick = async event => {
    event.preventDefault();

    this.setState({ isConfirming: true });

    console.log(this.state);

    try {
      let result = await CSAuth.handleConfirmClick(this.state.email, this.state.code, this.state.password);
      console.log(result);
      if (result.error) {
        let message = "Please verify your data - code/password";

        if (result["error"]["code"] === "LimitExceededException") {
          message = "Password change limit exceeded. Please try after some time."
        }

        this.setState({ message: message });
      } else {
        this.setState({ confirmed: true, message: "" });
      }
    } catch (e) {
      console.log(e);
      this.setState({ message: "Please verify your data - code/password" });
    }
  };

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  renderSuccessMessage() {
    return (
        <Grid container spacing={6} justify="center">
          <Grid item xs={12} sm={6}>
            <Link to="">
              Click here to login with your new credentials.
            </Link>
          </Grid>
        </Grid>
    );
  }

  renderConfirmationForm(classes) {


    return (
        <Grid container spacing={6} justify="center">
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <Grid container spacing={8} alignItems="flex-end" justify="center"
                    alignItems="center">
                <Grid item>
                  <Security/>
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField id="code" label="Code" type="email"
                             fullWidth autoFocus required
                             value={this.state.code}
                             onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item>
                  <Fingerprint/>
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField id="password"
                             label="Password" type="password" fullWidth required
                             value={this.state.password}
                             onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" style={{ marginTop: '10px' }}>
                <Link style={{margin: "10px"}} to="/reset">Back</Link>
                <Button variant="contained"
                        color="primary"
                        style={{ textTransform: "none" }}
                        onClick={this.handleConfirmClick}
                >Submit
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
    )
  }

  // request to inout code and create new pwd
  renderRequestCodeForm(classes) {
    return (
        <Grid container spacing={6} justify="center">
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <h4>Please change your password</h4>
              <div className="p-rules">
                <p>Min. length of the password is 6.</p>
              </div>

              <Grid container spacing={8} alignItems="flex-end" justify="center"
                    alignItems="center">
                <Grid item>
                  <Email/>
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField id="email" label="Email" type="email"
                             fullWidth autoFocus required
                             value={this.state.email}
                             onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" style={{ marginTop: '10px' }}>
                <Button variant="contained"
                        color="primary"
                        style={{ textTransform: "none" }}
                        onClick={this.handleSendCodeClick}
                >Submit
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
    );
  }

  // request to change password
  handlePasswordChange = event => {
    console.log(this.state);
  }


  render() {
    const { classes } = this.props;
    return (
        <div className="ResetPassword">
          <div className={"msg"}>
            {this.state.message}
          </div>
          <div>
            {!this.state.codeSent
                ? this.renderRequestCodeForm(classes)
                : !this.state.confirmed
                    ? this.renderConfirmationForm(classes)
                    : this.renderSuccessMessage()
            }
          </div>
        </div>
    );
  }
}

export default withStyles(styles)(observer(ResetPassword));