import React, {Component} from "react";

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
        <div>
          <h1>FAQ</h1></div>
    )
  }
}

export default FAQ;